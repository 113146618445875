<template>
  <div class="results">
    <div class="score">
      <p>Your results</p>
      <span class="total-score">{{ score }}/{{ totalQuestions }}</span>
      <p>You earned {{ xpEarned }} points</p>
    </div>

    <ol class="answers" ref="results">
      <li v-for="(question, index) in questions" :key="question.id">
        <h3>{{ question.question }}</h3>
        <p>Your answers: {{ getAnswerText(question, userAnswers[index]) }}</p>
        <span role="alert" v-if="isAnswerCorrect(question, userAnswers[index])" class="correct-answers">
          Correct!
        </span>
        <span role="alert" v-else class="incorrect-answers">
          Incorrect. The correct answer is:
          {{ getAnswerText(question, question.correctAnswer) }}
        </span>
        <div class="voting">
          <button @click="vote(question.id, 'yes')">👍</button>
          <button @click="vote(question.id, 'no')">👎</button>
        </div>
      </li>
    </ol>
    <base-button class="download-bt" @click="downloadPDF">Download Results</base-button>
  </div>
</template>

<script>
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { doc, updateDoc, arrayUnion } from "firebase/firestore";
import { db } from "@/firebase"; // Adjust the import according to your Firebase setup
import BaseButton from "./BaseButton.vue";

export default {
  name: "ResultsComponent",
  props: {
    score: {
      type: Number,
      required: true,
    },
    totalQuestions: {
      type: Number,
      required: true,
    },
    questions: {
      type: Array,
      required: true,
      default: () => [],
    },
    userAnswers: {
      type: Array,
      required: true,
      default: () => [],
    },
    xpEarned: {
      type: Number,
      required: true,
    },
  },
  methods: {
    getAnswerText(question, answerValue) {
      const answer = question.options?.find((option) => option.value === answerValue);
      return answer ? answer.text : "N/A";
    },
    isAnswerCorrect(question, userAnswer) {
      return userAnswer === question.correctAnswer;
    },
    async downloadPDF() {
      const element = this.$refs.results;
      const canvas = await html2canvas(element);
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save("quiz-results.pdf");
    },
    async vote(questionId, vote) {
      if (!questionId) {
        console.error("Question ID is undefined");
        return;
      }
      try {
        const questionRef = doc(db, "quizQuestions", questionId);
        await updateDoc(questionRef, {
          votes: arrayUnion({ vote, timestamp: Date.now() })
        });
        alert("Thank you for your feedback!");
      } catch (error) {
        console.error("Error voting on question: ", error);
      }
    }
  },
  components: { BaseButton }
};
</script>

<style scoped>
.results {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: min(95%, 65ch);
  margin: 0 auto;
}

.score {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  color: white;
  background-color: var(--main-color);
  border-radius: var(--card-radius);
  padding-block: var(--padding-y);
  padding-inline: var (--padding-x);
  box-shadow: var (--card-shadow);
  margin-bottom: var (--margin-y);
}

.total-score {
  margin: 0;
  font-size: 80px;
  color: white;
  font-size: calc(var(--h1-size) *2);
  font-weight: 700;
}

.answers {
  list-style: none;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.answers li {
  display: flex;
  flex-direction: column;
  gap: calc(var(--gap-spacing)/2);
  margin-bottom: calc(var(--margin-y)*2);
  line-height: 1.25;
}

.answers h3 {
  font-weight: 500;
  margin-bottom: var(--margin-y);
}

.correct-answers {
  color: green;
}

.incorrect-answers {
  color: red;
}

.download-bt {
  display: flex;
  justify-content: center;
  margin-block: var(--margin-y);
  margin-bottom: calc(var(--margin-y) *2);
}

.voting {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.voting p {
  margin: 0;
}

.voting button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
  transition: ease 0.3s all;
}

.voting button:hover {
  transition: ease 0.2s all;
  transform: scale(1.25);
}
</style>
