<script setup>

</script>

<template>
    <footer class="footer">
        <p class="footer-title">Quiz Me GPT by </p>
        <p class="footer-link">
            <a href="https://chrisdalbano.com" target="_blank">
                Chrisdalbano
            </a>
            <!-- &
            <a href="https://github.com/Seevt" target="_blank">
                Seevt
            </a> -->
        </p>
        <!-- <a class="footer-link" href="https://chrisdalbano.com" target="_blank">chrisdalbano.com</a> -->
    </footer>
</template>



<style  scoped>
.footer {
    /* display: flex; */
    display: block;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: calc(var(--gap-spacing)/2);
    padding-block: calc(var(--padding-y)/2 + .2rem);
    background: var(--main-color);
    color: white;
}

.footer a {
    color: white;
    transition: all 0.15s ease;
    text-decoration: none;
}



.footer a:hover {
    text-decoration: underline;
}



.footer-title {
    cursor: default;
    text-align: center;
    font-weight: 600;
    font-family: var(--poppins);
    /* letter-spacing: .4px; */
    font-size: calc(var(--h2-size)/1.5);
}

.footer-link {
    text-decoration: none;
    display: flex;
    justify-content: center;
    font-weight: 400;
    font-size: calc(var(--p-size)/1.2);
    font-style: italic;
    gap: calc(var(--gap-spacing)/2)
}

.footer-link a {
    font-size: calc(var(--p-size)/1.2);

}
</style>