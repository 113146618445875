<template>
  <button :style="style">
    <slot></slot>
  </button>
</template>

<script setup>
import { ref, defineProps } from "vue";

const props = defineProps({
  textColor: {
    type: String,
    default: "white",
  },
  hoverTextColor: {
    type: String,
    default: "black",
  },
  color: {
    type: String,
    default: "#333",
  },
  hoverColor: {
    type: String,
    default: "#333",
  },
  padding_y: {
    type: String,
    default: "0.5rem",
  },
  padding_x: {
    type: String,
    default: "1rem",
  },
});

let style = ref({
  "--textColor": props.textColor,
  "--hoverTextColor": props.hoverTextColor,
  "--color": props.color,
  "--hover-color": props.hoverColor,
  "--padding-x-axis": props.padding_x,
  "--padding-y-axis": props.padding_y,
});
</script>

<style scoped>
button {
  border: none;
  color: var(--textColor);
  padding-block: var(--padding-y-axis);
  padding-inline: var(--padding-x-axis);
  border-radius: 41px;
  width: fit-content;
  background: var(--color);
  cursor: pointer;
  transition: all 0.3s ease;
}

button:hover {
  background: var(--hover-color);
  color: var(--hoverTextColor);
}

button:disabled {
  background-color: #a0a0a0;
  cursor: not-allowed;
}
</style>
