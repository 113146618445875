<template>
  <div class="home-view">
    <floating-background />
    <home-nav />
    <div class="wrapper">
      <div class="container">
        <h1>
          {{
            loggedInUser
              ? `Welcome back!, What do you want to be tested about?`
              : `Let's create a quiz! What do you
          want to be tested about ?`
          }}
        </h1>
        <p v-if="!loggedInUser" class="paragraph">
          Log into your account to review your Quizzes
          <br />
          and get points from your matches!
        </p>
        <div class="input-wrapper">
          <input
            type="text"
            v-model="quizTopic"
            placeholder="Enter any topic..."
          />
        </div>
        <div role="quiz-actions" class="quiz-controls">
          <base-button
            color="var(--main-color)"
            hoverColor="#4eade4"
            @click="handleGenerateQuiz"
            :disabled="loading"
            class="disabledStyle"
          >
            Generate Quiz
          </base-button>
          <base-button
            textColor="hsl(0, 0%, 41%)"
            color="transparent"
            hoverTextColor="white"
            hoverColor="hsl(0, 0%, 41%)"
            @click="toggleAdvancedSettings"
            :disabled="loading"
            class="disabledStyle"
          >
            Quiz Settings
          </base-button>
          <button class="info-button" @click="toggleModal">?</button>
        </div>

        <p class="error-message">{{ errorMessage }}</p>
        <div v-if="loading" class="loading-spinner"></div>
        <p v-if="loading" class="waiting-text">
          We are generating a quiz for you
        </p>
      </div>
      <img :src="getImagePath" class="illustration" alt="illustration vector" />
    </div>

    <QuizComponent
      ref="quizComponent"
      v-if="quizGenerated"
      :questions="quizQuestions"
      @submit-answers="showResults"
    >
    </QuizComponent>

    <ResultsComponent
      v-if="resultsShown"
      :score="score"
      :totalQuestions="quizQuestions.length"
      :questions="quizQuestions"
      :userAnswers="userAnswers"
      :xpEarned="xpEarned"
    />
  </div>

  <vue-modal
    teleport="#home_modal"
    closeOnBackground
    :styling="{ overflowY: 'auto' }"
    defaultPosition
    :controller="homeModal"
  >
    <HomeModal :showModal="homeModal.show.value" @close="toggleModal" />
  </vue-modal>

  <vue-modal
    teleport="#home_modal"
    closeOnBackground
    :styling="{ overflowY: 'auto' }"
    defaultPosition
    :controller="advancedSettingsModal"
  >
    <AdvancedSettingsModal
      :showModal="advancedSettingsModal.show.value"
      @close="toggleAdvancedSettings"
      @save="handleSaveAdvancedSettings"
      :settings="advancedSettings"
    />
  </vue-modal>
</template>

<script setup>
import { ref, computed, nextTick } from "vue";
import { useStore } from "vuex";
import { onBeforeRouteLeave } from "vue-router";
import useQuizGenerator from "@/composables/useQuizGenerator";
import QuizComponent from "@/components/QuizComponent.vue";
import ResultsComponent from "@/components/ResultsComponent.vue";
import BaseButton from "@/components/BaseButton.vue";
import FloatingBackground from "@/components/FloatingBackground.vue";
import HomeNav from "@/components/HomeNav.vue";
import VueModal from "@/components/VueModal.vue";
import HomeModal from "@/components/HomeModal.vue";
import AdvancedSettingsModal from "@/components/AdvancedSettingsModal.vue";
import { useModalController } from "@/composables/useModal";
import { doc, collection, setDoc } from "firebase/firestore";
import { db } from "@/firebase";

const { generateQuiz: fetchQuiz, parseQuizText } = useQuizGenerator();

const difficultyLevel = ref("easy");
const quizTopic = ref("");
const questionCount = ref(3);
const quizType = ref("multiple-choice");
const quizGenerated = ref(false);
const quizQuestions = ref([]);
const resultsShown = ref(false);
const score = ref(0);
const loading = ref(false);
const userAnswers = ref([]);
const errorMessage = ref("");
const quizOutput = ref("");
const xpEarned = ref(0);
const quizComponent = ref(null);
const advancedSettingsModal = useModalController();

const advancedSettings = ref({
  difficulty: "easy",
  questionCount: 3,
  quizType: "multiple-choice",
});

const store = useStore();
const homeModal = useModalController();

const loggedInUser = computed(() => store.getters.loggedInUser);

const updateUserXp = (xp) => store.dispatch("updateUserXp", xp);
const saveQuizToHistory = (quizId) =>
  store.dispatch("saveQuizToHistory", quizId);

const getImagePath = computed(() => {
  return require(`@/assets/img/${
    !quizGenerated.value ? "home-vector-design.png" : "results-ill.png"
  }`);
});

function toggleModal() {
  if (homeModal.show.value) {
    homeModal.close();
  } else {
    homeModal.open();
  }
}

function toggleAdvancedSettings() {
  if (advancedSettingsModal.show.value) {
    advancedSettingsModal.close();
  } else {
    advancedSettingsModal.open();
  }
}

function handleSaveAdvancedSettings(settings) {
  try {
    difficultyLevel.value = settings.difficulty;
    questionCount.value = settings.questionCount;
    quizType.value = settings.quizType;
    advancedSettings.value = settings;
    // console.log("Advanced settings updated successfully:", settings);
  } catch (error) {
    console.error("Error updating advanced settings:", error);
  }
}

async function handleGenerateQuiz() {
  resultsShown.value = false;
  loading.value = true;

  const response = await fetchQuiz(
    quizTopic.value,
    difficultyLevel.value,
    questionCount.value,
    quizType.value
  );

  loading.value = false;
  quizOutput.value = response;

  if (response) {
    const questions = parseQuizText(response, quizType.value);

    if (questions !== null) {
      quizQuestions.value = questions;
      quizGenerated.value = true;
      errorMessage.value = "";

      if (loggedInUser.value) {
        const userId = loggedInUser.value.uid;

        // Save each question to Firestore and replace local IDs with Firestore IDs
        const updatedQuestions = await Promise.all(
          questions.map(async (question) => {
            const questionRef = doc(collection(db, "quizQuestions"));
            await setDoc(questionRef, question);
            return { ...question, id: questionRef.id };
          })
        );

        quizQuestions.value = updatedQuestions;

        // Save the quiz metadata to the quizHistory collection
        const quizRef = doc(collection(db, "quizHistory"));
        await setDoc(quizRef, {
          userId: userId,
          topic: quizTopic.value,
          difficulty: difficultyLevel.value,
          questionCount: questionCount.value,
          quizType: quizType.value,
          questions: updatedQuestions, // Use updated questions with Firestore IDs
          timestamp: new Date(),
        });
      }

      nextTick(() => {
        window.scrollTo({
          top: document.body.scrollHeight,
          behavior: "smooth",
        });
      });
    } else {
      console.error("Failed to parse questions from API response");
      errorMessage.value =
        "Failed to generate quiz. Please try a different topic.";
    }
  }
}

async function showResults(payload) {
  score.value = payload.score;
  userAnswers.value = payload.userAnswers; // Ensure userAnswers are properly set
  xpEarned.value = payload.xpEarned;
  resultsShown.value = true;
  quizGenerated.value = false;

  await updateUserXp(payload.xpEarned);
  await saveQuizToHistory({ ...payload });

  window.scrollBy({
    top: window.scrollY - 40,
    behavior: "smooth",
  });
}

onBeforeRouteLeave((next) => {
  if (quizGenerated.value) {
    if (
      confirm(
        "You are in the middle of a quiz, are you sure you want to leave this page?"
      )
    ) {
      next();
    } else {
      next(false);
    }
  }
});
</script>

<style scoped>
@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

.home-view {
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100svh;
}

.wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: min(95%, 65ch);
  margin-inline: auto;
  margin-block: 0;
  padding-inline: var(--padding-x);
  padding-block: var(--padding-y);
  text-align: center;
  border-radius: var(--card-radius);
  box-shadow: var(--card-shadow);
  background-color: #fff;
}

h1 {
  margin-bottom: var(--margin-y);
  color: #4e4e4e;
}

.paragraph {
  display: flex;
  justify-content: center;
  justify-items: center;
  margin-bottom: var(--margin-y);
  opacity: 75%;
}

.quiz-controls {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: var (--margin-y);
  gap: var(--margin-y);
}

.info-button {
  --min-size: 30px;
  --circle-size: min(5vw, 2rem);
  border: none;
  cursor: pointer;
  display: flex;
  padding-top: 1px;
  justify-content: center;
  align-items: center;
  background-color: hsl(0, 0%, 41%);
  color: white;
  border-radius: var(--input-radius);
  min-width: var(--min-size);
  min-height: var(--min-size);
  height: var(--circle-size);
  width: var(--circle-size);
  font-size: clamp(1rem, 1.8vw, 1.2rem);
}

.info-button:hover {
  background-color: #4e4e4e;
}

.input-wrapper {
  --input-sizing-y: clamp(0.4rem, 5%, 0.6rem);
  --b-style: 1px solid hsl(0, 0%, 70%);
  display: flex;
  flex-wrap: wrap;
  font-size: var(--p-size);
  width: min(100%, 50ch - 5ch);
  margin-block: calc(var(--margin-y) / 2);
  border-radius: var(--input-radius);
  padding-block: var(--input-sizing-y);
  padding-inline: var(--input-sizing-x);
  border: var(--b-style);
}

.input-wrapper input {
  flex: 2;
  border: none;
  background: transparent;
  border-bottom-left-radius: var (--b-radius);
  border-top-left-radius: var(--b-radius);
}

.input-wrapper input:focus {
  outline: none;
}

@media (max-width: 648px) {
  .input-wrapper {
    justify-content: center;
    gap: 5px;
    border-radius: 10px;
  }

  .input-wrapper::after {
    display: none;
  }

  .input-wrapper input {
    border-right: 0;
    border-bottom: var(--b-style);
    text-align: center;
  }

  .input-wrapper input::placeholder {
    text-align: center;
  }

  .remove-select-styles {
    padding: 0;
  }
}

.remove-select-styles {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  padding: 0 1.5em 0 1.5em;
  margin: 0;
  border: 0;
  outline: none;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;
}

.remove-select-styles::-ms-expand {
  display: none;
}

.select {
  font-size: var(--p-size);
  line-height: 1.1;
  cursor: pointer;
  position: relative;
  color: #4e4e4e;
  text-align: left;
}

.select:hover {
  opacity: 0.8;
}

@media (max-width: 648px) {
  .select {
    text-align: center;
  }
}

.illustration {
  margin: 0 auto;
  width: 317px;
  height: 317px;
}

@media (max-width: 600px) {
  .illustration {
    width: 200px;
    height: 200px;
    margin-top: var(--margin-y);
  }
}

.waiting-text {
  animation: bounce 1s ease infinite;
  margin-top: var(--margin-y);
}

.output-container {
  padding: 20px;
  width: 100%;
  max-width: 600px;
  margin: 20px auto;
  display: none;
  display: block;
}

#quiz-output {
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
  color: black;
}
</style>
