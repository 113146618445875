import axios from "axios";
import { v4 as uuidv4 } from 'uuid';

export default function useQuizGenerator() {
  const generateQuiz = async (topic, difficulty, questionCount = 3, quizType = "multiple-choice") => {
    if (questionCount > 12) {
      throw new Error("Number of questions exceeds the maximum limit of 12");
    }

    const apiKey = process.env.VUE_APP_CHATGPT_API_KEY;
    const model = "ft:gpt-3.5-turbo-1106:personal:qmgpt-fine-tuning1:9iofNfWY"; // Use your fine-tuned model

    let prompt = "";
    switch (quizType) {
      case "multiple-choice":
        prompt = `You're a QuizMaker. Create ${questionCount} ${difficulty} questions about ${topic}, each with 3 multiple choice ${difficulty} answers. Indicate the correct answer for each question with a letter (A, B, or C). Answer letters should use ')'. Provide the correct answer at the end by responding 'Correct Answer: {CorrectAnswerLetter}'`;
        break;
      case "true-false":
        prompt = `You're a QuizMaker. Create ${questionCount} true/false questions about ${topic}. Indicate the correct answer for each question with 'True' or 'False'. Format the questions like: "Question? True/False."`;
        break;
      case "a-b":
        prompt = `You're a QuizMaker. Create ${questionCount} A/B questions about ${topic}. For example: "The water is color... a) red b) transparent". Indicate the correct answer for each question with 'a' or 'b'. Format the questions like: "Question... a) option1 b) option2. Correct Answer: {CorrectAnswer}"`;
        break;
      default:
        throw new Error("Unsupported quiz type");
    }

    try {
      const response = await axios.post(
        "https://api.openai.com/v1/chat/completions",
        {
          model: model,
          messages: [{ role: "user", content: prompt }],
          temperature: 0.8,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiKey}`,
          },
        }
      );
      return response.data.choices[0].message.content;
    } catch (error) {
      console.error("Error generating quiz:", error);
      throw error;
    }
  };

  const parseQuizText = (text, quizType) => {
    let questions = [];
    try {
      switch (quizType) {
        case "multiple-choice": {
          const regex = /(?<=^|\n)\s*(.+)\s*\n*\s*A\)(.+)\s*B\)(.+)\s*C\)(.+)\s*\n*\s*(?:Correct\s*)?Answer:\s*(A|B|C)/gim;
          let match;
          while ((match = regex.exec(text)) !== null) {
            questions.push({
              id: uuidv4(),
              question: match[1].trim(),
              options: [
                { text: match[2].trim(), value: "A" },
                { text: match[3].trim(), value: "B" },
                { text: match[4].trim(), value: "C" },
              ],
              correctAnswer: match[5],
            });
          }
          break;
        }
        case "true-false": {
          const regex = /(.+)\.\s*(True|False)\.?/g;
          let match;
          while ((match = regex.exec(text)) !== null) {
            questions.push({
              id: uuidv4(),
              question: match[1].trim(),
              correctAnswer: match[2],
            });
          }
          break;
        }
        case "a-b": {
          const regex = /(.+)\.\.\.\s*a\)\s*(.+)\s*b\)\s*(.+)\.\s*Correct\s*Answer:\s*(a|b)/g;
          let match;
          while ((match = regex.exec(text)) !== null) {
            questions.push({
              id: uuidv4(),
              question: match[1].trim(),
              options: [
                { text: match[2].trim(), value: "a" },
                { text: match[3].trim(), value: "b" },
              ],
              correctAnswer: match[4].trim(),
            });
          }
          break;
        }
        default:
          throw new Error("Unsupported quiz type");
      }
    } catch (error) {
      console.error("Error parsing quiz text:", error);
      throw error;
    }
    return questions.length > 0 ? questions : null;
  };

  return {
    generateQuiz,
    parseQuizText,
  };
}
