<template>
  <div class="quiz-component">
    <div class="question">
      <b role="question-number" class="question-number">
        Question {{ currentQuestionIndex + 1 }} / {{ questions.length }}
      </b>
      <p role="question-description" class="question-title">
        {{ questions[currentQuestionIndex].question }}
      </p>
    </div>
    <ul v-if="currentQuestionIndex < questions.length" class="answer-options">
      <li v-for="(option, optionIndex) in getOptions(questions[currentQuestionIndex])"
          :key="optionIndex"
          :class="{ selected: userAnswers[currentQuestionIndex] === option.value }"
          @click="selectAnswer(option.value)">
        {{ option.text }}
      </li>
    </ul>

    <base-button class="quiz-button" hoverTextColor="var(--main-color)" @click="nextQuestion" v-if="currentQuestionIndex < questions.length - 1">
      Next Question
    </base-button>
    <base-button class="quiz-button" hoverTextColor="var(--main-color)" @click="submitAnswers" v-else>Submit</base-button>
  </div>
</template>

<script>
import BaseButton from "./BaseButton.vue";

export default {
  name: "QuizComponent",
  props: {
    questions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      userAnswers: [],
      currentQuestionIndex: 0,
    };
  },
  computed: {
    loggedInUser() {
      return this.$store.getters.loggedInUser;
    },
  },
  methods: {
    getOptions(question) {
      if (question.options) {
        return question.options;
      } else {
        // For true/false questions
        return [
          { text: "True", value: "True" },
          { text: "False", value: "False" },
        ];
      }
    },
    selectAnswer(answer) {
      this.userAnswers.splice(this.currentQuestionIndex, 1, answer);
    },
    nextQuestion() {
      if (this.userAnswers[this.currentQuestionIndex] !== null) {
        this.currentQuestionIndex++;
      }
    },
    submitAnswers() {
      let score = 0;
      for (let i = 0; i < this.questions.length; i++) {
        if (this.userAnswers[i] === this.questions[i].correctAnswer) {
          score++;
        }
      }
      const xpEarned = score * 10; // Modify this value to change XP per correct answer
      this.$emit("submit-answers", {
        score,
        xpEarned,
        questions: this.questions,
        userAnswers: this.userAnswers,
      });
    },
  },
  created() {
    this.userAnswers = Array(this.questions.length).fill(null);
  },
  components: { BaseButton },
};
</script>

<style scoped>
.quiz-component {
  display: flex;
  flex-direction: column;
  width: min(95%, 65ch);
  align-self: center;
}

.question {
  display: flex;
  flex-direction: column;
  gap: var(--gap-spacing);
}

.question .question-number {
  font-size: calc(var(--p-size) * 1.5);
  text-align: center;
  color: var(--text-color);
  opacity: 0.7;
}

.question .question-title {
  font-weight: 700;
  letter-spacing: 0.6px;
  color: hsla(0, 0%, 100%, 0.9);
  background: var(--main-color);
  padding-inline: var(--padding-x);
  padding-block: var(--padding-y);
  border-radius: var(--card-radius);
  box-shadow: var(--card-shadow);
}

.answer-options {
  display: flex;
  flex-direction: column;
  gap: var(--gap-spacing);
  margin-top: var(--margin-y);
  padding-inline: var(--padding-x);
  padding-block: var(--padding-y);
  background: hsla(0, 0%, 83%, 0.308);
  border-radius: var(--card-radius);
}

.answer-options li {
  list-style: none;
  cursor: pointer;
  border-radius: var(--card-radius);
  padding-block: var(--padding-y);
  padding-inline: var(--padding-x);
  background-color: white;
  font-size: calc(var(--p-size) * 1.1);
  transition: 0.25s;
  font-weight: 500;
  border: 1px solid transparent;
}

.answer-options li.selected {
  transition: 0.25s;
  border: 1px solid var(--text-color);
}

.quiz-button {
  margin-block: var(--margin-y);
  margin-inline: auto;
}
</style>
