<template>
  <div v-if="showModal" class="home-modal">
    <div class="modal-header">
      <h1>Quiz Settings</h1>
      <span class="close-button" @click="$emit('close')">&times;</span>
    </div>
    <div class="input-wrapper top">
      <div class="content-wrapper">
        <label for="difficulty">Difficulty:</label>
        <select v-model="localDifficulty" id="difficulty" class="custom-select">
          <option value="easy">Easy</option>
          <option value="medium">Medium</option>
          <option value="hard">Hard</option>
          <option value="emoji">Emojis</option>
        </select>
      </div>
      <div class="content-wrapper">
        <label for="questionCount">Number of Questions:</label>
        <div class="question-count">
          <button @click="decrementQuestionCount" class="count-button">-</button>
          <input
            type="text"
            v-model="localQuestionCount"
            id="questionCount"
            readonly
            class="question-input"
          />
          <button @click="incrementQuestionCount" class="count-button">+</button>
        </div>
      </div>
    </div>
    <div class="input-wrapper">
      <label for="quizType">Quiz Type:</label>
      <select v-model="localQuizType" id="quizType" class="custom-select">
        <option value="multiple-choice">Multiple Choice</option>
        <option value="true-false">True/False</option>
        <option value="a-b">A/B</option>
      </select>
    </div>
    <div class="modal-actions">
      <base-button @click="saveSettings" hoverTextColor="var(--main-color)" :disabled="isSaveDisabled">Save</base-button>
      <base-button @click="$emit('close')" hoverTextColor="var(--main-color)">Cancel</base-button>
    </div>
  </div>
</template>


<script>
import BaseButton from "@/components/BaseButton.vue";

export default {
  name: "AdvancedSettingsModal",
  components: {
    BaseButton,
  },
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
    settings: {
      type: Object,
      required: true,
      default: () => ({
        difficulty: "easy",
        questionCount: 3,
        quizType: "multiple-choice",
      }),
    },
  },
  data() {
    return {
      localDifficulty: this.settings.difficulty || "easy",
      localQuestionCount: this.settings.questionCount || 3,
      localQuizType: this.settings.quizType || "multiple-choice",
    };
  },
  computed: {
    isSaveDisabled() {
      return this.localQuestionCount < 1 || this.localQuestionCount > 12;
    },
  },
  methods: {
    saveSettings() {
      try {
        this.$emit("save", {
          difficulty: this.localDifficulty,
          questionCount: this.localQuestionCount,
          quizType: this.localQuizType,
        });
        // console.log("Advanced settings saved:", {
        //   difficulty: this.localDifficulty,
        //   questionCount: this.localQuestionCount,
        //   quizType: this.localQuizType,
        // });
        this.$emit("close");
      } catch (error) {
        console.error("Error saving settings:", error);
      }
    },
    incrementQuestionCount() {
      if (this.localQuestionCount < 12) {
        this.localQuestionCount++;
      }
    },
    decrementQuestionCount() {
      if (this.localQuestionCount > 1) {
        this.localQuestionCount--;
      }
    },
  },
};

</script>
<style scoped>
.home-modal {
  display: flex;
  flex-direction: column;
  gap: var(--gap-spacing);
  margin-block: var(--margin-y);
  background-color: #fefefe;
  padding-inline: var(--padding-x);
  padding-block: var(--padding-y);
  border: 1px solid #888;
  width: min(95%, 80ch);
  border-radius: var(--card-radius);
}

.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: var(--header-font-size);
}

.close-button {
  font-size: var(--close-button-size);
  cursor: pointer;
}

.close-button:hover,
.close-button:focus {
  color: var(--modal-border-color);
  text-decoration: none;
}

.input-wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1rem;
  align-items: flex-start;
}

.input-wrapper label {
  font-weight: bold;
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.custom-select,
.question-input {
  padding: 0.5rem;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 1rem;
  transition: border-color 0.3s ease;
  width: 100%;
  max-width: 300px; 
  background-color:#f3f3f3;
}

.custom-select:focus,
.question-input:focus {
  border-color: hidden;
  outline: none;
}

.question-count {
  display: flex;
  align-items: center;
}

.count-button {
  background-color: hsl(0, 0%, 41%);
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
  border-radius: 5px;
}

.count-button:hover {
  background-color: hsl(0, 0%, 28%);
}

.question-input {
  text-align: center;
  width: 4rem;
  margin: 0 0.5rem;
  border: none;
}

.modal-actions {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

.top {
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
  width: 100%; /* Ensure the elements take full width */
}

@media (max-width: 768px) {
  .home-modal {
    width: 95%;
  }

  .modal-header {
    font-size: 1.25rem;
  }

  .input-wrapper input,
  .input-wrapper select {
    font-size: 0.9rem;
  }

  .top {
    flex-direction: column;
  }
}

</style>
