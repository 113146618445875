import { createRouter, createWebHistory } from "vue-router";
import HomeView from "@/views/HomeView.vue";
import LogIn from "@/views/LogIn.vue";
import MyAccount from "@/views/MyAccount.vue";

import { computed } from "vue";
import store from "../store";

const loggedInUser = computed(() => store.getters.loggedInUser);

const routes = [
  {
    path: "/",
    name: "HomeView",
    component: HomeView,
  },
  
  {
    path: "/login",
    name: "LogIn",
    component: LogIn,
  },

  {
    path: "/my-account",
    name: "MyAccount",
    component: MyAccount,
    props: (route) => ({ loggedInUser: route.params.loggedInUser }),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to) => {
  if ((to.name == "MyAccount" && !loggedInUser.value) || !to.name) {
    return "/";
  }
});

export default router;
