<!-- Update MyAccount.vue -->
<template>
  <div v-if="!isLoading && loggedInUser" class="my-account">
    <floating-background />
    <user-nav
      @logout="logOut"
      :username="displayUsername(loggedInUser.email)"
    />

    <h2 v-if="userTitle" class="user-level">{{ userTitle }}</h2>
    <div v-if="userLevel !== null" class="user-score">
      <p>Your quiz score is</p>
      <span class="user-points">{{ userXp }}</span>
    </div>

    <div>
      <button class="history-bt" @click="toggleQuizHistory">
        <span class="past-quiz">
          <p>Past quizzes</p>
          <svg
            :style="isQuizHistoryOpen ? 'rotate: -180deg' : 'rotate: 0deg'"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class=""
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M19.5 13.5L12 21m0 0l-7.5-7.5M12 21V3"
            />
          </svg>
        </span>
      </button>

      <ol
        :style="
          isQuizHistoryOpen
            ? 'grid-template-rows: 1fr; background-color: #fff; box-shadow: var(--card-shadow);'
            : ''
        "
        class="quiz-history"
      >
        <div class="quiz-history-transition-container">
          <li
            v-for="quiz in quizHistoryData"
            :key="quiz.id"
            class="quiz-history-item"
          >
            <div class="quiz-content">
              <div class="quiz-header">
                <input v-model="quiz.topic" @change="updateQuizName(quiz)" />
                
                <p>Your score: {{ quiz.score }} / {{ quiz.totalQuestions }}</p>
              </div>
              <ol class="quiz-questions">
                <li
                  v-for="(question, qIndex) in quiz.questions"
                  :key="qIndex"
                  class="question-info"
                >
                  <p class="title">
                    {{ question.question }}
                  </p>
                  <p class="answer">Your answer: {{ question.userAnswer }}</p>
                  <p class="correct-answer">
                    Correct answer: {{ question.correctAnswer }}
                  </p>
                </li>
              </ol>
            </div>

            <button class="delete-button" @click="confirmDeleteQuiz(quiz.id)">
              &times;
            </button>
          </li>
        </div>
      </ol>
    </div>
  </div>
  <div v-else>
    <div class="loading-spinner"></div>
  </div>
</template>

<script>
import {
  doc,
  getDoc,
  collection,
  getDocs,
  query,
  where,
  orderBy,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";
import { db } from "@/firebase.js";
import { auth } from "@/firebase.js";
import { signOut } from "firebase/auth";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { ref, computed, watch, onMounted } from "vue";
import FloatingBackground from "@/components/FloatingBackground.vue";
import UserNav from "@/components/UserNav.vue";

export default {
  name: "MyAccount",
  props: {},
  setup() {
    const store = useStore();
    const router = useRouter();
    const userLevel = ref(null);
    const userTitle = ref(null);
    const userXp = ref(null);
    const isLoading = ref(true);
    const quizHistoryData = ref([]);
    const isQuizHistoryOpen = ref(false);
    const showQuizHistory = ref(false);

    function displayUsername(email) {
      let atIndex = email.indexOf("@");
      return email.slice(0, atIndex);
    }

    const loggedInUser = computed(() => store.getters.loggedInUser);

    const expPercentage = computed(() => {
      return (userXp.value / nextLevelXp.value) * 100;
    });

    const nextLevelXp = computed(() => {
      return userLevel.value * 100;
    });

    const logOut = async () => {
      try {
        await signOut(auth);
        router.push("/");
        store.commit("setLoggedInUser", null);
      } catch (e) {
        console.error(e);
      }
    };

    const fetchUserData = async () => {
      if (loggedInUser.value) {
        const userRef = doc(db, "users", loggedInUser.value.uid);
        const userSnap = await getDoc(userRef);
        if (userSnap.exists()) {
          const userData = userSnap.data();
          userLevel.value = userData.level;
          userTitle.value = userData.title;
          userXp.value = userData.xp;
          isLoading.value = false;
        }
      }
    };

    const deleteQuiz = async (quizId) => {
      if (!quizId) {
        console.error("QuizId is undefined");
        return;
      }
      try {
        const quizRef = doc(db, "quizHistory", quizId);
        await deleteDoc(quizRef);
        // After the quiz is deleted, fetch the updated quiz history.
        await fetchQuizHistory();
      } catch (error) {
        console.error("Error deleting document: ", error);
      }
    };

    const fetchQuizHistory = async () => {
      if (loggedInUser.value) {
        const userId = loggedInUser.value.uid;
        const quizHistoryRef = collection(db, "quizHistory");
        const querySnapshot = await getDocs(
          query(
            quizHistoryRef,
            where("userId", "==", userId),
            orderBy("timestamp", "desc")
          )
        );
        quizHistoryData.value = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
          totalQuestions: doc.data().questions.length, 
        }));
      }
    };

    const toggleQuizHistory = () => {
      isQuizHistoryOpen.value = !isQuizHistoryOpen.value;
    };

    const updateQuizName = async (quiz) => {
      try {
        const quizRef = doc(db, "quizHistory", quiz.id);
        await updateDoc(quizRef, {
          topic: quiz.topic,
        });
      } catch (error) {
        console.error("Error updating quiz name: ", error);
      }
    };

    const editQuizName = (quiz) => {
      console.log(`Editing quiz: ${quiz.id}`);
    };

    const confirmDeleteQuiz = async (quizId) => {
      if (confirm("Are you sure you want to delete this quiz?")) {
        await deleteQuiz(quizId);
      }
    };

    watch(loggedInUser, () => {
      fetchUserData();
      fetchQuizHistory();
    });
    onMounted(async () => {
      await fetchUserData();
      await fetchQuizHistory();
    });

    return {
      displayUsername,
      logOut,
      loggedInUser,
      isLoading,
      userLevel: computed(() => userLevel.value),
      userTitle: computed(() => userTitle.value),
      userXp: computed(() => userXp.value),
      expPercentage: computed(() => expPercentage.value),
      nextLevelXp: computed(() => nextLevelXp.value),
      quizHistoryData: computed(() => quizHistoryData.value),
      isQuizHistoryOpen: computed(() => isQuizHistoryOpen.value),
      toggleQuizHistory,
      showQuizHistory,
      deleteQuiz,
      updateQuizName,
      editQuizName,
      confirmDeleteQuiz,
    };
  },
  components: { FloatingBackground, UserNav },
};
</script>

<style scoped>
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

* {
  list-style: none;
}

.my-account {
  display: flex;
  align-self: center;
  flex-direction: column;
  width: min(95%, 65ch);
  flex-grow: 1;
  text-align: center;
  gap: var(--gap-spacing);
}

.user-level,
.user-score {
  padding-block: var(--padding-x);
  border-radius: var(--card-radius);
  padding-block: var(--padding-y);
  box-shadow: var(--card-shadow);
  background-color: #fff;
  letter-spacing: 0.6px;
  color: rgba(43, 43, 43, 0.8);
}

.user-level {
  background: var(--main-color);
  color: #fff;
  letter-spacing: 1px;
}

.user-points {
  color: var(--main-color);
  font-size: calc(var(--h1-size) * 2);
  font-weight: 700;
}

.past-quiz {
  display: flex;
  align-items: center;
  padding-bottom: 0.25rem;
  cursor: pointer;
}

.past-quiz p {
  flex-grow: 1;
  text-align: left;
}

.past-quiz svg {
  width: 2rem;
  height: 1.5rem;
  justify-self: end;
}

.history-bt {
  border: none;
  background: none;
  color: hsl(0, 0%, 25%);
  font-weight: bold;
  letter-spacing: 0.5px;
  width: 100%;
  cursor: pointer;
  margin-bottom: calc(var(--gap-spacing) / 2);
}

.quiz-history {
  display: grid;
  grid-template-rows: 0fr;
  transition: 350ms all ease;

  border-radius: var(--card-radius);
  margin-bottom: var(--gap-spacing);
  padding-block: var(--padding-y);
  padding-inline: var(--padding-x);
}

.quiz-history-transition-container {
  overflow: hidden;
}

.quiz-history-item {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.quiz-history-item h2 {
  text-align: left;
}

.quiz-history-item .delete-button {
  border: none;
  background-color: transparent;
  height: fit-content;
  font-size: var(--h2-size);
  cursor: pointer;
  color: red;
}

.quiz-content {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--margin-y);
  width: 100%;
}

.quiz-header {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.quiz-header p {
  justify-self: center;
  width: 100%;
  margin-bottom: calc(var(--margin-y) / 3);
}

.quiz-header input {
  border: none;
  padding: 0.5rem;
  font-size: var(--h2-size);
  font-weight: bold;
  color: var(--main-color);
}

.edit-button img {
  width: 16px; /* Set appropriate width */
  height: 16px; /* Set appropriate height */
}

.quiz-questions {
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: var(--gap-spacing);
}

.question-info {
  display: flex;
  flex-direction: column;
  gap: calc(var(--gap-spacing) / 4);
}

.loading-spinner {
  display: flex;
  width: 80px;
  height: 80px;
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid #ffc973;
  animation: spin 0.7s linear infinite;
  margin: 300px auto;
}

.loading-spinner {
  display: flex;
  width: 80px;
  height: 80px;
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid #ffc973;
  animation: spin 0.7s linear infinite;
  margin: 300px auto;
}
</style>
